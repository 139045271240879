import { render, staticRenderFns } from "./index.vue?vue&type=template&id=ef75b804&scoped=true&v-cloak=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=ef75b804&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef75b804",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/smart-form-user-pc_K7ye/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ef75b804')) {
      api.createRecord('ef75b804', component.options)
    } else {
      api.reload('ef75b804', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=ef75b804&scoped=true&v-cloak=true", function () {
      api.rerender('ef75b804', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/agency/index.vue"
export default component.exports