<template v-cloak>
  <div class="app-container" style="position: relative; width: 90%;min-height:100%;">
    <div class="my-form-right">
      <div class="right-form-list">
        <div class="form-list-top">
          <el-form :inline="true" size="small" @submit.native.prevent style="float: left;">
            <el-form-item style="margin-bottom: 0;">
              <el-input v-model="queryParams.name" placeholder="请输入应用或表单名称" prefix-icon="el-icon-search" clearable style="width: 240px;font-size: 14px;" @clear="onResetQuery" />
            </el-form-item>
            <!-- <el-form-item style="margin-bottom: 0;">
                             <formTagEditor v-model="queryParams.tagName" :multiple="false" :selectonly="true" :tagList="tagList" style="width: 160px;"></formTagEditor>
                        </el-form-item> -->
            <el-form-item style="margin-bottom: 0;">
              <el-button type="primary" icon="el-icon-search" @click="agencyHomeList" plain>搜索</el-button>
              <el-button icon="el-icon-refresh" @click="onResetQuery">重置</el-button>
            </el-form-item>
          </el-form>
          <el-radio-group v-model="listStyle" size="mini" class="list-switch">
            <el-radio-button label="card"><i class="el-icon-bank-card"></i> 卡片</el-radio-button>
            <el-radio-button label="list"><i class="el-icon-tickets"></i> 列表</el-radio-button>
          </el-radio-group>
        </div>
        <fillList :listStyle="listStyle" :list="projectList" @get-refresh-list="agencyHomeList" @editProject="onEditProject($event)" :cur="cur" :isShowListError="isShowListError" :tagList="tagList" />
        <div class="list-no-data" v-if="projectList.length == 0">
          <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list_create_no.png" class="no-data-img">
          <span class="no-data-text">暂无机构表单~</span>
        </div>
        <pagination v-show="total > this.queryParams.pageSize || (queryParams.pageSize!=16 && pageSize!=5)" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" :pageSizes.sync="pageSizes" @pagination="pagination" />
        <div style="width:100%; text-align: center; color:#909090; font-size: 12px; padding: 20px 10px 0px;position:absolute;bottom:5px;left:0px;right:0px;">技术支持：北京鲸鱼岛科技有限公司</div>
      </div>
    </div>


    <!-- 强制绑定该用户的手机号 -->
    <bindUserMobile></bindUserMobile>
    <project-detail-edit ref="projectDetailEditor" :user="user" :tagList.sync="tagList" @updateProjectList="agencyHomeList"></project-detail-edit>
   
  </div>
</template>

<script>
import fillList from '@/views/system/form/list/fillList'

import { getToken } from '@/utils/auth'
import { getSessionStorageObj, setSessionStorageObj } from '@/utils/db'
import { agencyHomeList, projectDetail,getTagListByUserId } from '@/api/system/form'
import { aliUploader } from '@/utils/oss'
import bindUserMobile from '../form/components/forceBindMobile/bindUserMobile'
import fileUploadList from '../form/components/fileUploadList.vue'
import projectDetailEdit from '@/views/system/form/components/projectDetailEdit/index.vue'
export default {
  name: 'Form',
  components: {
    fillList, bindUserMobile, projectDetailEdit
  },
  data () {
    return {
      listStyle: 'card',
      user: {},
      total: 1,
      cur: 1,
      // 创建应用相关
      newProjectOpen: false,
      queryParams: {
        pageNum: 1,
        pageSize: 16,
        name: null,
        startDate: null,
        endDate: null,
        tagName: ""
      },
      project: {
        name: '',
        cover: 'https://smart-form-dev.oss-cn-beijing.aliyuncs.com/fillFile/12/1508788130356920320/formInfoFiles/1676268588466.png',
        details: '',
        fileList: [],
        tagList: []
      },
      open: false,
      tagList:[],
      formFilesUpload: {},
      pageSizes: [16, 24, 32, 48],
      hasScrollbar: false, // 当滚动条高度 this.scrollHeight 大于60时是否定位
      scrollHeight: 60,
      agencyId: null,
      projectList: [],
      isShowListError: false,
      sessionStorageUser: getSessionStorageObj('users')
    }
  },
  async created () {
    if (this.agencyId == null) {
      this.agencyId = getSessionStorageObj('agencyId') || this.sessionStorageUser.agencyIds.split(',')[0]
    }
    
    this.$store.dispatch("GetUserInfo").then((res) => {
      this.user = res.data;
    });
    this.agencyHomeList()
    this.getTagListByUserId();

    this.Authorization = getToken();
  },
  mounted () {
    window.addEventListener('scroll', this.initHeight);
    this.formFilesUpload = aliUploader(false, {
      uploadPath: 'fillFile/{agencyId}/{formId}/formInfoFiles/'
    }, () => {
      return this.project.fileList
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.initHeight, false)
  },
  watch: {
    'project.fileList': {
      deep: true,
      handler () {
        this.fileList = this.project.fileList;
      }
    },
    'listStyle' :{
      deep: true,
      handler() {
        if(this.listStyle === 'list'){
          this.queryParams.pageSize = 5;
          this.agencyHomeList()
        } else {
          this.queryParams.pageSize = 16;
          this.agencyHomeList()
        }
      }
    },
  },
  methods: {
    onShowNewProjectDialog(){

    },
    // 实现吸顶效果，判断滚动条距离顶部的距离
    initHeight () {
      const self = this
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      self.hasScrollbar = scrollTop > self.scrollHeight
    },
    agencyHomeList () {
      agencyHomeList({
        agencyId: this.agencyId,
        name: this.queryParams.name || undefined,
        pageSize: this.queryParams.pageSize,
        pageNum: this.queryParams.pageNum
      }).then(res => {
        if (res.code === 200) {
          this.projectList = res.rows
          this.total = res.total
        }
      })
    },
    onResetQuery () {
      this.queryParams.name = null
      this.agencyHomeList()
    },
    pagination (data) {
      console.log('pagination', data)
      this.queryParams.pageNum = data.page
      this.queryParams.pageSize = data.limit
      this.agencyHomeList()
    },
    // 修改应用
    onEditProject (data) {
      this.projectId = data
      this.$refs.projectDetailEditor.editProject(data);
    },
    async getTagListByUserId () {
      let res = await getTagListByUserId();
      this.tagList = res.data.tagList;
    },
    getProjectDetail (id) {
      const _this = this
      projectDetail(id).then((response) => {
        if (response.code == 200) {

          _this.newProjectOpen = true
          _this.$nextTick(() => {

            _this.project.name = response.data.name;
            _this.project.cover = response.data.cover;
            _this.project.details = response.data.details;
            _this.project.tagList = response.data.tagList;
            _this.project.fileList = response.data.fileList;

            // 给aliOSS设置agencyId
            _this.formFilesUpload.config = {
              formId: response.data.projectId,
              agencyId: response.data.agencyId
            }
          })

          _this.$forceUpdate();
        }
      })
    },
    onCloseNewProjectDialog () {
      this.newProjectOpen = false;
      this.clearProjectDialogData()
    },
    clearProjectDialogData () {
      this.$refs.projectForm.resetFields()
      this.$refs['projectForm'].clearValidate()
      this.projectId = null
      this.project = {
        name: '',
        cover: 'https://smart-form-dev.oss-cn-beijing.aliyuncs.com/fillFile/12/1508788130356920320/formInfoFiles/1676268588466.png',
        details: '',
        fileList: []
      }
    },
    onHideNewProjectDialog () {
      this.newProjectOpen = false
      // this.editor.destroy()
      // this.editor = null
      this.clearProjectDialogData()
    },
    onSubmitNewProjectClick () {
      this.$refs['projectForm'].validate((valid) => {
        if (valid) {
          if (!this.isBlank(this.projectId)) {
            this.httpEditProject()
          } else {
            this.httpCreateProject()
          }
        }
      })
    },
    // 远程、跨域裁剪（兼容IE9）
    editCropper() {
      const _this = this
      _this.open = true
      setOssConfig()
      _this.$nextTick(()=> {
        console.log(_this.$refs.imgCutterModal)
        _this.$refs.imgCutterModal.handleOpen({
          name: 'project_default.jpeg',
          src: _this.project.cover + '?any_string_is_ok'
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import url('~@/assets/styles/filetype.css');
[v-cloak] {
  display: none;
}
.my-form-left {
  background: #ffffff;
  border-radius: 4px;
  .tab-title {
    margin: 0;
    padding: 16px;
    li {
      width: 100%;
      text-align: left;
      cursor: pointer;
      height: 40px;
      line-height: 40px;
      margin: 0 0 8px;
      list-style: none;
      font-size: 14px;
      color: #333333;
      background: rgba(165, 171, 177, 0.05);
      border-radius: 4px;
      font-weight: 500 !important;
      &.active {
        background: rgba(0, 92, 238, 0.08);
        color: #3476f0;
        position: relative;
        border-radius: 6px;
        font-weight: 500 !important;
        &::before {
          position: absolute;
          content: '';
          width: 4px;
          height: 30px;
          left: 0;
          top: 50%;
          margin-top: -15px;
          background: #3476f0;
          opacity: 0.4;
          border-radius: 0 3px 3px 0;
        }
      }
      &:hover {
        background: rgba(0, 92, 238, 0.08);
        color: #3476f0;
        border-radius: 6px;
        font-weight: 500 !important;
      }

      .tab-title-icon {
        width: 18px;
        height: 18px;
        margin-left: 10px;
        margin-right: 8px;
        vertical-align: -5px;
      }
    }
  }
  &.isFixed {
    position: fixed;
    top: 0;
    -webkit-transform: translateZ(0);
  }
}
.my-form-right {
  width: 1184px;
  min-height: 220px;
  padding: 16px;
  border-radius: 4px;
  box-sizing: border-box;
  background: #ffffff;
  padding-bottom:30px;
  // border-left: 1px solid #e5e5e5;
  flex: 1;
  -webkit-flex: 1;
  .form-list-top {
    width: 100%;
    box-sizing: border-box;
    height: 45px;
    .form-top-btn {
      font-size: 14px;
      height: 28px;
      &:hover {
        opacity: 0.8;
      }
    }
    .btn-create-icon {
      font-size: 14px;
      margin-right: 4px;
    }
  }
  .tab-content {
    width: 100%;
    height: 100%;
  }
}
.anim-fade-in {
  animation: 1s slideLeftActive;
}
@keyframes slideLeftActive {
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(0);
  }
}
.uploader-hover {
  position: absolute;
  width: 200px;
  height: 120px;
  line-height: 120px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  &:hover {
    background: rgba(0, 0, 0, 0.35);
    &:after {
      content: '修改封面图片';
      font-size: 14px;
    }
  }
  .uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 128px;
    line-height: 128px;
    text-align: center;
  }
}
.cover {
  width: 100%;
  height: 100%;
  display: block;
}
.line {
  padding: 0;
  text-align: left;
  margin-left: 30px;
  span {
    display: block;
    line-height: 20px;
    font-size: 13px;
    color: #999999;
  }
}
.form-type-content {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: space-around;
  .type-content-default {
    width: 50%;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: #909090;
    img {
      display: inline-block;
      vertical-align: middle;
      width: 156px;
      height: 120px;
      margin-top: 40px;
    }
  }
  .type-content-page {
    width: 50%;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: #909090;
    img {
      display: inline-block;
      vertical-align: middle;
      width: 135px;
      height: 126px;
      margin-top: 40px;
    }
  }
}

.list-no-data {
  width: 100%;
  margin: 130px auto 230px;
  text-align: center;
  .no-data-img {
    display: block;
    width: 370px;
    height: auto;
    margin: auto;
  }
  .no-data-text {
    display: block;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: #909090;
    margin-top: 37px;
  }
}

.list-switch {
  background-color: #f7f7f7;
  padding: 2px;
  border-radius: 4px;
  float: right;
  ::v-deep .el-radio-button__inner {
    background-color: #f7f7f7;
    color: #999999;
    padding: 5px 12px;
    border: none;
    border-radius:4px;
    box-shadow: none;
  }
  ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: #333333;
    background-color: #fff;
    border-color: #dcdfe6;
    -webkit-box-shadow: -1px 0 0 0 #dcdfe6;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
  }
}
</style>
